import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="tom-select"
export default class extends Controller {
  static targets = ["select", "externalData", "tags", "emails", "componentField"]

  selectTargetConnected(element) {
    new TomSelect(element,{
      sortField: {
        field: "text",
        direction: "asc"
      }
    });
  }

  emailsTargetConnected(element){
    let loading = 0
    const emails = new TomSelect(element,{
      create: true,
      valueField: 'email',
      labelField: 'email',
      searchField: ['email'],
      createFilter: element.dataset.regex,
      onItemAdd(value, $item){
        console.log("OnItemAdd Trigger", this)
        this.setTextboxValue('')
      },
      load: function(query, callback) {
        let url = element.dataset.url
        if( loading >= 1 ){
          callback();
          return;
        }
        // if(query){url = url + '?q=' + encodeURIComponent(query);}
        fetch(url)
        .then(response => response.json())
        .then(json => {
          loading++
          callback(json);
          this.setValue(element.dataset.startingValue)
        }).catch(()=>{
          callback();
        });
      },
    });
    let selfEmail = document.querySelector("#add-self-email")
    selfEmail.addEventListener("click", function() {
      emails.createItem(selfEmail.dataset.email);
      selfEmail.closest('form').requestSubmit()
    });
  }

  componentFieldTargetConnected(element){
    const dropdownId = element.id;
    const startingInforNumber = element.dataset.startingInforNumber
    const tomSelect = new TomSelect(element,{
      valueField: 'id',
      labelField: 'part_number',
      searchField: ['part_number', 'name', 'infor_number', 'description'],
      // fetch remote data
      load: function(query, callback) {
        let url = element.dataset.url
        let warehouse = document.querySelector("#part_order_warehouse_id")
        if(query){
          url = url + '?q=' + encodeURIComponent(query);
          if(warehouse){
            url = url + `&warehouse_id=${warehouse.value}`
          }
        }
        fetch(url)
        .then(response => response.json())
        .then(json => {
          callback(json);
          console.log("Done Loading")
          this.setValue(element.dataset.startingValue)
        }).catch(()=>{
          callback();
        });
      },
      render: {
        option: function(data, escape) {
          return `<div><div class="flex border-dotted border-b gap-6 ${escape(data.background)}">
                    <div class="grid content-center">
                      ${data.main_image ? `<img class="object-contain" src="${escape(data.main_image)}" width="150" height="150">` : ''}
                    </div>
                    <div class="grid content-center">
                      <div>
                      <div clas="flex">
                        <h4>${escape(data.part_number)}</h4>
                        <div class="ml-auto text-danger">${escape(data.message)}</div
                      </div>
                      <h3>${escape(data.name)}</h3>
                      <h6>${escape(data.infor_number)}</h6>
                      <small>${escape(data.description)}</small>
                    </div></div>
                  </div></div>`;
        },
        item: function(data, escape) {
          return `<div><div class="flex gap-6 item ${escape(data.background)}">
          <div class="grid content-center">
            ${data.main_image ? `<img class="object-contain" src="${escape(data.main_image)}" width="150" height="150">` : ''}
          </div>
          <div class="grid content-center">
            <div>
            <h3>${escape(data.name)}</h3>
            <h4>${escape(data.part_number)}</h4>
            <h5>${escape(data.infor_number)}</h5>
            <h6>${escape(data.description)}</h6>
          </div></div>
        </div></div>`;
        }
      }
    })
    if(startingInforNumber){
      console.log("Load Values")
      tomSelect.load(startingInforNumber, "SOMETHING")
    }
    console.log("Set Value")
    tomSelect.setValue(element.dataset.startingValue);
  }

  tagsTargetConnected(element) {
    new TomSelect(element,{
      valueField: 'id',
      labelField: 'display',
      searchField: ['name', 'display'],
      render: {
        option: function(data, escape) {
          if(data.display){
            return JSON.parse(data.display);
          }
        },
        item: function(data, escape) {
          if(data.display){
            return JSON.parse(data.display);
          }
        }
      }
    });
  }

}

