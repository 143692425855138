import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="shipments"
export default class extends Controller {
  static targets = ["refreshPartOrders"]
  // addShipmentItemBtn = document.querySelector("#add-shipment-item-btn")
  // shipmentTable = document.querySelector("#shipment-table")
  // shipmentForm = document.querySelector("#new_shipment")

  refreshPartOrdersTargetConnected(){
    document.querySelector("#part_orders_shipments").reload()
  }

  matchingRow(element){
    if (element.dataset.partOrderItemId){
      return this.findMatchingRow(element.dataset.partOrderItemId, 'part_order_item')
    }else{
      return this.findMatchingRow(element.dataset.shipmentItemId, 'shipment_item')
    }
  }

  addItem(event){
    let matchingRow = this.matchingRow(event.target)
    this.setValues(event.target, matchingRow, 1)
    this.setShipmentValues()
    this.checkHiddenElements(event.target)
  }

  getElement(row){
    if (row.querySelector('input.part_order_item').value > 0){
      return document.querySelector("[data-part-order-item-id='" +  row.querySelector('input.part_order_item').value + "']")
    }else{
      return document.querySelector("[data-shipment-item-id='" +  row.querySelector('input.shipment_item').value + "']")
    }
  }

  removeItem(event){
    const row = event.target.closest('tr')
    const element = this.getElement(row)
    const quantity = Number(row.querySelector('input.quantity').value) * -1
    this.setValues(element, row, quantity)
    this.setShipmentValues()
    this.checkHiddenElements(element)
  }

  addAllItems(event){
    const table = event.target.closest('.card').querySelector('.part_order_items_table')
    for (var i = 1, element; element = table.rows[i]; i++) {
      const dataItem = element.querySelector('.part_order_data')
      if(dataItem && dataItem.dataset.quantity > 0){
        let matchingRow = this.matchingRow(dataItem)
        this.setValues(dataItem, matchingRow, dataItem.dataset.quantity);
        this.setShipmentValues()
        this.checkHiddenElements(dataItem)
      }
    };
  }

  getShippingAddressId(element, option=false){
    if(element.querySelector('input.part_order_item').value > 0){
      if(option){
        return document.querySelector("[data-part-order-item-id='" +  element.querySelector('input.part_order_item').value + "']").dataset.addressId
      }else{
        return document.querySelector("[data-part-order-item-id='" +  element.querySelector('input.part_order_item').value + "']").dataset.shippingAddressId
      }
    }else{
      if(option){
        return document.querySelector("[data-shipment-item-id='" +  element.querySelector('input.shipment_item').value + "']").dataset.addressId
      }else{
        return document.querySelector("[data-shipment-item-id='" +  element.querySelector('input.shipment_item').value + "']").dataset.shippingAddressId
      }
    }
  }

  setShipmentValues(){
    document.querySelector(".simple_form").querySelector(".shipment_shipping_address_id input").value = null
    let shippingAddressId = null
    let addressId = null
    for (var i = 1, element; element = document.querySelector("#shipment-table").rows[i]; i++) {
      if(element.style.display != 'none'){
        shippingAddressId = this.getShippingAddressId(element)
        addressId = this.getShippingAddressId(element, true)
        document.querySelector(".simple_form").querySelector(".shipment_shipping_address_id input").value = addressId
        document.querySelector("#ship_to_address").innerHTML = document.querySelector("#shipping_address_"+ shippingAddressId + " .ship_to").innerHTML
      }
    };
    document.querySelector("#shipping_addresses").querySelectorAll('.card').forEach(element => {
      if(shippingAddressId){
        if(element.id == "shipping_address_"+shippingAddressId){
          element.hidden = false
        }else{
          element.hidden = true
        }
      }else{
        document.querySelector("#ship_to_address").innerHTML = ""
        if(element.getElementsByClassName('good').length <= 0){
          element.hidden = true
        }else{
          element.hidden = false
        }
      }
    });
  }


  setValues(element, row, quantity){
    const ShippingRatesController = this.application.getControllerForElementAndIdentifier(document.querySelector("#shipping-rates-controller"), 'shipping-rates')
    ShippingRatesController.setServiceValue(element.dataset.serviceType)

    this.assignQuotedShippingValue()

    if(element.dataset.partOrderItemId > 0){
      row.querySelector("input.part_order_item").value = element.dataset.partOrderItemId
    }
    if(element.dataset.shipmentItemId > 0){
      row.querySelector("input.shipment_item").value = element.dataset.shipmentItemId
    }
    row.querySelector(".component_part_number").innerHTML = element.dataset.componentPartNumber
    row.querySelector(".component_infor_number").innerHTML = element.dataset.componentInforNumber
    row.querySelector(".component_name").innerHTML = element.dataset.componentName
    row.querySelector(".component_description").innerHTML = element.dataset.componentDescription
    row.querySelector(".component_image").innerHTML = element.dataset.image
    row.querySelector(".component_id").dataset.value = element.dataset.componentId

    if(document.querySelector("#shipment_weight").dataset.force_manual_weight == false){
      document.querySelector("#shipment_weight").value = Number(document.querySelector("#shipment_weight").value) + (Number(quantity) * element.dataset.weight)
    }

    row.querySelector("input.quantity").value = (Number(row.querySelector("input.quantity").value) + Number(quantity))
    row.querySelector(".quantity_value").innerHTML = Number(row.querySelector("input.quantity").value)
    element.dataset.quantity = Number(element.dataset.quantity) - Number(quantity)
    element.closest('tr').querySelector(".quantity").innerHTML = element.dataset.quantity
    row.querySelector("input.quantity").dispatchEvent(new Event('input'));
  }

  checkHiddenElements(element){
    if(element.dataset.quantity <= 0){
      element.closest('tr').hidden = true
      element.closest('tr').classList.remove("good")
    }else{
      element.closest('tr').hidden = false
      element.closest('tr').classList.add("good")
    }

    if(element.closest('.card').getElementsByClassName('good').length <= 0){
      element.closest('.card').hidden = true
    }else{
      element.closest('.card').hidden = false
    }
  }

  assignQuotedShippingValue(){
    console.log("Adding Shipping Quote Rate")
    const serviceType = document.querySelector('#shipment_service_type').value
    if(serviceType){
      let service = document.querySelector(`[data-service-type='${serviceType}'`)
      if (service){
        document.querySelector("#shipment_quoted_shipping_cost").value = service.dataset.price
      }
    }
  }

  assignMatchingRow(id, type){
    if (type == 'part_order_item'){
      return document.querySelector("#shipment-table").querySelector("input[value='"+ id +"'].part_order_item")
    }else{
      return document.querySelector("#shipment-table").querySelector("input[value='"+ id +"'].shipment_item")
    }
  }

  findMatchingRow(id, type){
    let found = this.assignMatchingRow(id, type)
    if(!found){
      document.querySelector("#add-shipment-item-btn").click()
      this.makeVisible(document.querySelector("#shipment-table").rows[document.querySelector("#shipment-table").rows.length - 1])
      return document.querySelector("#shipment-table").rows[document.querySelector("#shipment-table").rows.length - 1]
    }else{
      this.makeVisible(found.closest('tr'))
      return found.closest('tr')
    }
  }

  makeVisible(row){
    row.querySelector('input.destroy_field').value = false
    row.style = null
  }

}
