import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="boms"
export default class extends Controller {
  connect() {
  }

  selectWarehouse(event){
    document.querySelector('#top-modal-content').src = `${document.querySelector('#top-modal-content').src}&warehouse_id=${event.target.value}`
  }
}
