import { Controller } from "@hotwired/stimulus"
// import Shepherd from 'shepherd.js';

// Connects to data-controller="shepherd"
export default class extends Controller {

  static targets = ["homepage", "partordersNew", "partordersIndex", "partordersShow"]

  connect() {
  }

  // newTour(){
  //   return new Shepherd.Tour({
  //     useModalOverlay: true,
  //     defaultStepOptions: {
  //       cancelIcon: {
  //         enabled: true
  //       },
  //       classes: '',
  //       scrollTo: { behavior: 'smooth', block: 'center' }
  //     }
  //   });
  // }

  // homepageTargetConnected(element){
  //   element.remove()
  //   const tour = this.newTour()

  //   tour.addStep({
  //     id: 'first-step',
  //     arrow: true,
  //     text: 'Welcome to the MotoService Portal.',
  //     classes: '',
  //     buttons: [
  //       {
  //         text: 'Next',
  //         action: tour.next,
  //         class: ''
  //       }
  //     ]
  //   });

  //   tour.addStep({
  //     id: 'second-step',
  //     arrow: true,
  //     text: 'This is the menu to navigate through the website.',
  //     attachTo: {
  //       element: '.menu-items',
  //       on: 'right'
  //     },
  //     classes: 'ml-5',
  //     buttons: [
  //       {
  //         text: 'Next',
  //         action: tour.next,
  //         class: ''
  //       }
  //     ]
  //   });

  //   tour.addStep({
  //     id: 'second-step',
  //     arrow: true,
  //     text: 'Click this icon to toggle the menu.',
  //     attachTo: {
  //       element: '.menu-toggler',
  //       on: 'right'
  //     },
  //     classes: 'ml-5',
  //     buttons: [
  //       {
  //         text: 'Next',
  //         action: tour.next,
  //         class: ''
  //       }
  //     ]
  //   });

  //   tour.addStep({
  //     id: 'third-step',
  //     arrow: true,
  //     text: 'The dashboard will provide a snapshot of your part orders.',
  //     attachTo: {
  //       element: '.dashboard-cards',
  //       on: 'right'
  //     },
  //     classes: 'ml-5',
  //     buttons: [
  //       {
  //         text: 'Next',
  //         action: tour.next,
  //         class: ''
  //       }
  //     ]
  //   });

  //   tour.addStep({
  //     id: 'third-step',
  //     arrow: true,
  //     text: 'Hover over this area to view your profile, change your password, or sign out.',
  //     attachTo: {
  //       element: '.avatar',
  //       on: 'bottom'
  //     },
  //     classes: 'ml-5',
  //     buttons: [
  //       {
  //         text: 'Next',
  //         action: tour.next,
  //         class: ''
  //       }
  //     ]
  //   });

  //   tour.start()
  // }

  // partordersIndexTargetConnected(element){
  //   element.remove()
  //   const tour = this.newTour()

  //   tour.addStep({
  //     id: 'first-step',
  //     arrow: true,
  //     text: 'This is where all the part orders will be.',
  //     classes: '',
  //     buttons: [
  //       {
  //         text: 'Next',
  //         action: tour.next,
  //         class: ''
  //       }
  //     ]
  //   });

  //   tour.addStep({
  //     id: 'second-step',
  //     arrow: true,
  //     modalOverlayOpeningRadius: 10,
  //     modalOverlayOpeningPadding: 10,
  //     text: 'Here are the past part orders.',
  //     attachTo: {
  //       element: '#part_orders_index',
  //       on: 'bottom'
  //     },
  //     classes: 'mt-5',
  //     buttons: [
  //       {
  //         text: 'Next',
  //         action: tour.next,
  //         class: ''
  //       }
  //     ]
  //   });

  //   tour.addStep({
  //     id: 'second-step',
  //     arrow: true,
  //     modalOverlayOpeningRadius: 10,
  //     modalOverlayOpeningPadding: 10,
  //     text: 'Click the headers to sort by that attribute.',
  //     attachTo: {
  //       element: 'thead',
  //       on: 'bottom'
  //     },
  //     classes: 'mt-5',
  //     buttons: [
  //       {
  //         text: 'Next',
  //         action: tour.next,
  //         class: ''
  //       }
  //     ]
  //   });

  //   tour.addStep({
  //     id: 'third-step',
  //     arrow: true,
  //     modalOverlayOpeningRadius: 10,
  //     modalOverlayOpeningPadding: 10,
  //     text: 'Search for Retailer, PO#, Status, or Date.',
  //     attachTo: {
  //       element: '[data-turbo-frame="part_orders_index"]',
  //       on: 'bottom'
  //     },
  //     classes: 'mt-5',
  //     buttons: [
  //       {
  //         text: 'Next',
  //         action: tour.next,
  //         class: ''
  //       }
  //     ]
  //   });

  //   tour.start()
  // }


}
