import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="external-shipment"
export default class extends Controller {
  static targets = ["method", "service"]


  shipping_addresses = this.setupShippingAddress()

  setupShippingAddress(){
    const object = document.querySelector("#shipment_shipping_address_id")
    if (object && object.dataset.tomselect){
      return new TomSelect("#shipment_shipping_address_id",{
        valueField: 'id',
        labelField: 'full_address',
        searchField: ['company_name', 'name', 'address_line_1', 'address_line_2', 'city', 'state_province', 'postal_code', 'country'],
        // fetch remote data
        load: function(query, callback) {
          let url = document.querySelector("#shipment_shipping_address_id").dataset.dataUrl
          // url = url + "?retailer_id=" + document.querySelector("#shipment_shipping_address_id").dataset.retailer
          if(query){
            url = url + '?q=' + encodeURIComponent(query);
          }
          fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json);
          }).catch(()=>{
            callback();
          });
        },
        create: function(input, _callback) {
          const newLink = document.querySelector("#add_new_shipping_address")
          if(newLink){
            newLink.click()
          }
        },
        render: {
          option: function(data, escape) {
            return JSON.parse(data.full_address);
          },
          item: function(data, escape) {
            return JSON.parse(data.full_address);
          }
        }
      });
    }
  }

  connect() {
    this.changeShippingMethod()
  }

  changeShippingMethod(){
    this.methodTargets.forEach(element => {
      if(element.dataset.id == this.serviceTarget.value){
        element.hidden = false
      }else{
        element.hidden = true
      }
    });
  }
}
