import { Controller } from "@hotwired/stimulus"
import Toastify from "toastify-js";
import { showBackdrop, hideBackdrop } from "../components/backdrop"
import { animateCSS, openCollapse, closeCollapse } from "../components/script"
import tippy from "tippy.js";
import Litepicker from "litepicker";


export default class extends Controller {

  static targets = ["toast", "closeModal", "showTopModal", "dropdown", "refreshPage", "hiddenItem", "resetForm", "aside", "refreshFrame", "litepicker"]
  asideModal = document.querySelector("#aside-modal");
  modal = document.querySelector("#top-modal");
  aside = document.querySelector("#aside");

  tab(event) {
    if(event.params.valueCheck){
      if(event.target.value){
      }else{
        return;
      }
    }

    let toggling = false
    const target = event.target.dataset.target
    const tabs = event.target.closest(".tabs");
    let activeTabTrigger = tabs.querySelector(".tab-nav .active")
    if (activeTabTrigger){
    }else{
      activeTabTrigger = tabs.querySelector(".tab-nav .nav-link");
      activeTabTrigger.classList.add('active')
    }
    const activeTab = tabs.querySelector(".collapse.open");
    const targetedTab = tabs.querySelector(target);
    const trigger = tabs.querySelector(`[data-target="${target}"].nav-link`)
    if (toggling) return;
    if (activeTabTrigger === trigger) return;

    // Trigger
    activeTabTrigger.classList.remove("active");
    trigger.classList.add("active");

    // Tab
    // Close
    toggling = true;

    closeCollapse(activeTab, () => {
      openCollapse(targetedTab, () => {
        toggling = false;
      });
    });
  };


  refreshPageTargetConnected(element) {
    element.remove()
    location.reload()
  }

  refreshFrameTargetConnected(element) {
    setTimeout(() => {
      console.log(element.closest('turbo-frame'))
      element.closest('turbo-frame').setAttribute('src', element.dataset.src)
    }, 10000);
  }

  asideTargetConnected(){
    this.aside.hidden = false
  }



  asideTargetDisconnected(){
    this.aside.hidden = true
    this.aside.querySelector("#aside-sidebar").innerHTML = ""
  }

  dropdownTargetConnected(element){
    let placement = "bottom"
    if(element.dataset.tipyPlacement){
      placement = element.dataset.tipyPlacement
    }
    tippy(element, {
      theme: "light-border",
      offset: [0, 8],
      arrow: false,
      placement: placement,
      interactive: true,
      allowHTML: true,
      maxWidth: 'none',
      animation: "shift-toward-extreme",
      content: (reference) => {
        let dropdownMenu
        if(reference.closest(".dropdown").querySelector(".dropdown-menu")){
          dropdownMenu = reference.closest(".dropdown").querySelector(".dropdown-menu")
        }else{
          dropdownMenu = reference.closest(".dropdown").querySelector(".custom-dropdown-menu")
        }
        dropdownMenu = dropdownMenu.outerHTML;
        return dropdownMenu;
      },
      appendTo: () => element,
    });
  }

  connect() {

  }


  litepickerTargetConnected(element){
    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 30));
    const picker = new Litepicker({
      element: element,
      numberOfColumns: 2,
      numberOfMonths: 2,
      startDate: priorDate,
      endDate: today,
      singleMode: false
    })
  }

  closeAsideModalTargetConnected(element){
    element.remove();
    this.closeAsideModal()
  }

  closeTopModalTargetConnected(element){
    element.remove();
    this.closeTopModal()
  }

  showAsideModal() {
    showBackdrop();
    this.asideModal.classList.add("active");
    const animation = this.asideModal.dataset.animations.split(", ")[0];
    const modalContent = this.asideModal.querySelector(".modal-content");
    animateCSS(modalContent, animation);
  };

  // Close
  closeAsideModal() {
    hideBackdrop();
    const animation = this.asideModal.dataset.animations.split(", ")[1];
    const modalContent = this.asideModal.querySelector(".modal-content");
    animateCSS(modalContent, animation).then(() => {
      this.asideModal.classList.remove("active");
    });
  };

  showTopModal() {
    showBackdrop();
    this.modal.classList.add("active");
    const animation = this.modal.dataset.animations.split(", ")[0];
    const modalContent = this.modal.querySelector(".modal-content");
    animateCSS(modalContent, animation);
  };

  closeTopModal() {
    hideBackdrop();
    const animation = this.modal.dataset.animations.split(", ")[1];
    const modalContent = this.modal.querySelector(".modal-content");
    animateCSS(modalContent, animation).then(() => {
      this.modal.classList.remove("active");
    });
  };

  showTopModalTargetConnected(element){
    this.showTopModal()
    element.remove()
  }

  toastTargetConnected(element){
    console.log("toast connected")
    const elem = element.cloneNode(true)
    element.remove()
    delete elem.dataset.controller
    elem.classList.remove("hidden")
    let toast = Toastify({
      node: elem,
      duration: 6000,
      newWindow: true,
      close: false,
      gravity: "bottom",
      position: "right",
      stopOnFocus: true,
      onClick: function () {
        toast.hideToast();
      },
    }).showToast();
  }

  toggleHiddenItems(event){
    const target = event.target.dataset.target
    this.hiddenItemTargets.forEach(ele => {
      if(target){
        if(ele.dataset.target == target){
          ele.toggleAttribute("hidden")
        }
      }else{
        ele.toggleAttribute("hidden")
      }
    });
  }

  changeTurboTag(event){
    const target = event.target.dataset.target
    const url = event.target.dataset.url
    const param = event.target.dataset.param
    const value = event.target.value
    console.log(target, url, param, value)
    if(target){
      if(param){
        if(value){
          document.querySelector("#"+target).src = url+"?"+param+"="+value
        }
      }else{
        document.querySelector("#"+target).src = url
      }
    }
  }

  resetFormTargetConnected(element){
    const form = document.querySelector("#"+element.dataset.form)
    element.remove();
    if(form){
      form.reset();
    }
  }

  formSubmit(event){
    event.target.form.requestSubmit()
  }

  refreshFrame(event){
    const delay = event.target.dataset.delay
    const frame = event.target.closest('turbo-frame')
    console.log(frame)
    if(frame){
      setTimeout(function() { frame.reload(); }, (delay ? delay : 1000));
    }
  }


}
