import { Controller } from "@hotwired/stimulus"
// import 'sortablejs'

// Connects to data-controller="sortable"
export default class extends Controller {

  connect() {

  }
}
