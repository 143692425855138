import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  connect() {
  }

  reset(event){
    event.target.form.reset()
    const tomSelected = event.target.form.querySelectorAll(".tomselected")
    if (tomSelected.length > 0){
      tomSelected.forEach((tomselectedElement) => {
        tomselectedElement.tomselect.clear();
      });
    }
    this.element.requestSubmit()
  }

  search(event){
    let delay = 200
    if(event.params.wait){
      delay = event.params.wait
    }
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, delay)
  }

}

