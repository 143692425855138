import { Controller } from "@hotwired/stimulus"
import { Grid } from "gridjs"

// Connects to data-controller="gridjs"
export default class extends Controller {
  static targets = ["table"]

  connect() {
  }

  tableTargetConnected(element){
    const grid = new Grid({
      search: true,
      fixedHeader: true,
      height: '24rem',
      from: element
    }).render(element.nextElementSibling)
  }
}


