import { Application } from "@hotwired/stimulus"
import "chartkick/chart.js"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

document.addEventListener("turbo:before-fetch-request", async (event) => {
    event.preventDefault()

    if(event.target.dataset.loading){
      event.target.innerHTML = "<div class='flex justify-center'><span class='la la-spinner text-3xl animate-spin'></span></div>"
    }

    event.detail.resume()
  })

export { application }
