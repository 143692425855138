import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-actions"
export default class extends Controller {

  static targets = ["disableableItem", "hiddenItem", "filename"]

  connect() {
    console.log("Form Actions Controller")
  }

  change_file(event){
    if(this.filenameTarget){
      if(event.target.value){
        if(event.target.files.length > 1){
          this.filenameTarget.innerHTML = `(${event.target.files.length}) Files Selected`
        }else{
          this.filenameTarget.innerHTML = event.target.value.replace(/.*[\/\\]/, '')
        }
      }else{
        this.filenameTarget.innerHTML = this.filenameTarget.dataset.defaultValue
      }
    }
  }


  submitForm(event){
    const spinner = event.target.form.querySelector(".loading-spinner")

    let delay = 50
    if(event.target.dataset.wait){delay = event.target.dataset.wait}
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if(spinner){
        event.target.form.addEventListener("turbo:submit-start", async () => {
          spinner.hidden = false
        })
        event.target.form.addEventListener("turbo:submit-end", async () => {
          spinner.hidden = true
        })
      }
      event.target.form.requestSubmit()
    }, delay)
  }

  toggleDisableableItems(){
    this.disableableItemTargets.forEach(ele => {
      ele.toggleAttribute("disabled")
    });
  }

  toggleHiddenItems(){
    console.log("Hidden Items Toggle")
    console.log(this.hiddenItemTargets)
    this.hiddenItemTargets.forEach(ele => {
      ele.toggleAttribute("hidden")
    });
  }

}
